.hero {
  margin-top: 60px;
  width: 100%;
  height: calc(75vh - 60px);
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-between;
}

.hero-main {
  margin: 80px 0px;
}

.hero_left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-right: 30px;
  line-height: 30px;
}

.hero_left .hero_contant h1 {
  margin-bottom: 10px;
  color: var(--bg-color);
}

.hero_contant {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.hero_left .hero_contant .downloadthisApp {
  margin-top: 40px;
  font-size: 15px;
}

.hero_left .hero_contant .hero_btn {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.hero_left .hero_contant .hero_btn button {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 7px 20px;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 500;
  background-color: var(--bg-color);
  color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.hero_btn button .appleIcon {
  font-size: 16px !important;
}

.hero_left .hero_contant .hero_btn button:last-child {
  background-color: transparent;
  border: 2px solid var(--bg-color);
  color: var(--bg-color);
}

.hero_right {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  padding: 0 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  position: relative;
}

.hero_right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: rgba(20, 58, 95, 0.5);
}

/* .float-text {
  position: relative;
} */

.hero-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
}

.hero-top {
  margin: 0px 20px 20px 20px;
}

li {
  color: rgba(178, 178, 178, 1);
  font-size: 20px;
  line-height: 20px;
  padding: 16px 10px 16px 10px;
}

li:hover {
  color: rgba(254, 115, 50, 1);
  cursor: default;
}

hr {
  color: rgba(233, 237, 240, 1);
}

h1 {
  font-size: 52px;
  line-height: 78px;
  font-weight: 600;
  color: rgba(20, 58, 95, 1);
}

.text2-small {
  color: rgba(112, 111, 108, 1);
  font-size: 20px;
  line-height: 30px;
}

.text3-small {
  font-size: 18px;
  line-height: 28px;
  color: white;
}

.hero-right-child1 {
  margin-bottom: 20px;
}

.hero-right-child2 {
  margin-bottom: 50px;
}

.white-button {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px;
  border-radius: 100px;
  padding: 10px 10px;
  margin: 10px;
}


@media (max-width: 768px) {
  .hero {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
  }

  .hero_left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;

  }

  .hero_left .hero_contant h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .hero_left .hero_contant p {
    font-size: 12px;
  }

  .hero_left .hero_contant .hero_btn {
    display: flex;
    gap: 10px;
    margin-top: 40px;
  }

  .hero_left .hero_contant .hero_btn button {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 10px;
  }

  .hero_right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }

  .hero_right img {
    width: 350px;
  }
}

@media (max-width: 600px) {
  .hero {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero_left {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    order: 2;
  }

  .hero_left .hero_contant h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .hero_left .hero_contant p {
    font-size: 10px;
  }

  .hero_left .hero_contant .downloadthisApp {
    margin-top: 20px;
    font-size: 12px;
  }

  .hero_left .hero_contant .hero_btn {
    margin-top: 20px;
  }

  .hero_left .hero_contant .hero_btn button {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 7px 25px;
  }

  .hero_right {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    order: 1;
  }

  .hero_right img {
    width: 360px;
  }
}

@media (max-width: 490px) {
  .hero {
    margin-top: 60px;
    width: 100%;
    /* height: calc(100vh - 60px); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .hero-main {
    margin: 20px 10px;
    width: 100%;
    height: 100%;
  }

  .hero-top {
    margin: 0px 20px;
  }

  .hero_left {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    order: 2;
  }

  .hero_left .hero_contant h1 {
    font-size: 22px;
    /* font-weight: 400; */
    margin-bottom: 10px;
  }

  .hero_left .hero_contant p {
    font-size: 10px;
  }

  .hero_left .hero_contant .downloadthisApp {
    margin-top: 20px;
    font-size: 12px;
  }

  .hero_left .hero_contant .hero_btn button {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 7px 25px;
  }

  .hero_right {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    order: 1;
  }

  h1 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 600;
    color: rgba(20, 58, 95, 1);
  }

  .text2-small {
    color: rgba(112, 111, 108, 1);
    font-size: 16px;
    line-height: 25px;
  }

  .text3-small {
    font-size: 18px;
    line-height: 28px;
    color: white;
  }

  .hero-right-child1 {
    margin-bottom: 20px;
  }

  .hero-right-child2 {
    margin-bottom: 50px;
  }

  .white-button {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px;
    border-radius: 100px;
    padding: 10px 10px;
    margin: 10px;
  }

  .blue_btn {
    color: white;
    background-color: rgba(20, 58, 95, 1);
    border-radius: 1000px;
    padding: 10px 28px;
  }

  

}