.card-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.left-section {
    width: 30%;
    margin: 20px;
    height: fit-content;
}

.card-item {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px;
    font-size: 20px;
    border-bottom: 2px solid rgba(233, 237, 240, 1);
    color: rgba(178, 178, 178, 1);
}

.card-item:hover {
    background-color: #e0e0e0;
}

.card-item.active {
    /* font-weight: bold; */
    /* background-color: #d0d0d0; */
    color: rgba(254, 115, 50, 1);
    border-bottom: 2px solid rgba(233, 237, 240, 1);
}

.blue_btn {
    color: white;
    background-color: rgba(20, 58, 95, 1);
    border-radius: 1000px;
    padding: 8px 40px;
    margin-top: 120px;
    font-size: 16px;
}

.right-section {
    /* flex: 1; */
    height: 70vh;
    margin: 20px;
    width: 70%;
}

.detailed-card {
    position: relative;
    width: 100%;
    height: 100%;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 24px;
}

.card-details {
    position: relative;
    padding: 30px;
    background-color: rgba(20, 58, 95, 0.46);
    border-radius: 24px;
    height: 70vh;
    color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.card-details li {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 1);
    border-radius: 100px;
    padding: 5px 20px;
    margin: 6px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
}

.card-details ul {
    border-radius: 100px;
    margin: 10px;
    display: flex;
    padding: 0px;
}

/* Styling for mobile view */
.card-page {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
}

@media (max-width: 490px) {
    .card-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        width: 100%;
        /* position: static; */
    }

    .mobileview-image {
        width: 100%;
        height: 30vh;
        border-radius: 24px;
    }

    .img-grandient {
        width: 100%;
        height: 27vh;
        border-radius: 24px;
        background-position: center;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
    }

    .img-grandient::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        background-color: rgba(20, 58, 95, 0.46);
    }

    .card-page p {
        color: rgba(20, 58, 95, 1);
        font-size: 16px;
        font-weight: 450;
        line-height: 28px;
        text-align: center;
    }

    .card-page h2 {
        color: rgba(254, 115, 50, 1);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 10px 4px;
    }

    .card-page ul li {
        color: rgba(254, 115, 50, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        list-style-type: disc;
        padding: 4px 8px;
    }

    .blue_btn {
        padding: 10px 100px;
        margin: 30px 16px;
    }

    .card-container a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}