.popup{
    position: fixed;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    width: 700px;
    height: 500px;
    background-color: rgb(235, 235, 235);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow-y: hidden;
}

.popup_left{
width: 50%;
height: 100%;
background-color: rgb(241, 241, 241);
}
.popup_right{
    position: relative;
    width: 50%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup_right h1{
    margin-bottom: 30px;
    font-size: 19px;
    color: var(--bg-color);
}
.popup_right span{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px !important;
    cursor: pointer;
    color: var(--bg-color);
}

/* form{
} */
.popup_right form .inputs{
    margin-bottom: 10px;
    width: 280px;
}
.popup_right form .inputs input{
    width: 100%;
    border: none;
    outline: none;
    padding: 7px 8px;
    border-radius: 8px;
    background-color: rgb(233, 233, 233);
}
textarea{
    width: 100%;
    border: none;
    outline: none;
    height: 100px;
    padding: 5px 8px;
    border-radius: 8px;
    background-color: rgb(233, 233, 233);
}
.inputs button{
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 8px 8px;
    cursor: pointer;
    background-color: var(--bg-color);
    color: white;
}


@media(max-width:600px){
    .popup{
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%,-50%);
        z-index: 3;
        width: 400px;
        height: 500px;
        background-color: rgb(235, 235, 235);
        border-radius: 6px;
    }
    .popup_left{
        width: 50%;
        height: 100%;
        background-color: bisque;
        display: none;
        }
        .popup_right{
            width: 100%;
            height: 100%;
            background-color: rgb(255, 255, 255);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
}

@media(max-width:420px){
    .popup{
        position: fixed;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%,-50%);
        z-index: 3;
        width: 340px;
        height: 500px;
        background-color: rgb(235, 235, 235);
        border-radius: 6px;
    }
}