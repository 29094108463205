.services_page {
    margin-top: 70px;
    height: auto;
    padding: 20px 0;
}

.service_heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.service_heading h1 {
    font-size: 36px;
    font-weight: 600;
    color: rgba(20, 58, 95, 1);
}

.services {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    padding: 0 20px;
}

.card-title {
    margin-left: 10%;
    /* Adjust the gap as needed */
}

.serviceCard {
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}

.serviceCard::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.card-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    font-size: 24px;
}

.service-contant {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
}

.service-contant h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.service-contant p {
    font-size: 14px;
    margin-bottom: 10px;
}

.service-contant button {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 8px 20px;
    color: var(--bg-color);
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.faq-with-a {
    display: flex;
    flex-direction: column;
    padding: 0 298px;
    width: 100%;
}

.faq-subdiv {
    margin: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border-bottom: 1px solid rgba(233, 237, 240, 1); */
    
}

.faq-question {
    display: flex;
    justify-content: space-between;
}

.questions {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
}

.plusminusbtn {
    /* background-color: white; */
    font-size: 30px;
    font-weight: 300px;
    border: 0px;
    cursor: pointer;
}

.answers {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 20px 0px 40px;
    color: rgba(112, 111, 108, 1);
}

@media (max-width: 768px) {
    .service_heading h1 {
        font-size: 30px;
    }

    .serviceCard {
        width: calc(50% - 15px);
        margin-bottom: 30px;
    }
}

@media (max-width: 600px) {
    .serviceCard {
        width: calc(100% - 20px);
    }
}

@media (max-width: 490px) {
    .service_heading h1 {
        font-size: 36px;
        margin-bottom: 10px;
        padding-left: 10px;
    }
    .service_heading {
        font-size: 36px;
        margin-right: 10px;
    }
    .services_page {
        margin-top: 20px;
        padding: 0px;
    }
    .faq-with-a {
        padding: 0 30px;
    }
    .questions {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500
    }
    .plusminusbtn{
        margin-left: 10px;
    }
}