footer {
    /* background-color:rgb(226, 84, 84) ; */
    background-color: rgba(0, 0, 0, 1);
}

.footer {
    height: 300px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    color: var(--bg-color);
    max-width: 1200px;
    margin: 0px auto 0px auto;
    scroll-behavior: auto;
    /* background-color: rgba(0, 0, 0, 1); */
}

.footer__logo {
    height: 100%;
    /* width: 90%; */
}

.footer__logo h1 {
    margin-top: 30px;
}

.footer__logo__image {
    margin-top: 30px;

}

.footer__list {
    height: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.footer__list h3 {
    font-size: 13px;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    margin-left: 50px;
    color: rgba(112, 111, 108, 1);
}

.social-icons {
    display: flex;
    gap: 20px;
    /* Adjust the gap between icons */
    margin-top: 30px;
}

.social-icons span {
    display: inline-block;
}

.footer__list ul li {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 560;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    ;
}

.footer__logo__image img {
    /* width: 132px;
    height: 36px; */
    cursor: pointer;
}

.footer_bottom h4 {
    margin-top: 30px;
    color: var(--bg-color);
}

.footer_icons {
    display: flex;
    margin-top: 5px;
    gap: 10px;
}

.footer_icons span {
    cursor: pointer;
    background-color: var(--bg-color);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;

}

.footertext {
    color: white;
    font-size: 14px;
    line-height: 22px;
    margin-top: 25%;
}

.iconbtn {
    border-radius: 40px;
    padding: 4px 4px 0px 4px;
    border: 0px;
}

@media (max-width: 780px) {
    .footer {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        color: var(--bg-color);
        scroll-behavior: auto;
        padding: 10px 20px;
    }

    .footer__logo__image img {
        width: 80px;
        cursor: pointer;
    }
}

@media (max-width: 600px) {}

@media (max-width: 540px) {
    .footer {
        padding: 10px 15x;
        height: auto;
        grid-template-columns: 1fr 1fr;
        color: var(--bg-color);
        scroll-behavior: auto;
        overflow: hidden;

    }

    .footer__logo__image img {
        width: 90px;
        cursor: pointer;
    }

}

@media (max-width: 460px) {
    .footer {
        display: grid;
        grid-template-columns: 1fr;
    }

    .footer__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;
    }

    .footer_icons span {
        cursor: pointer;
        background-color: var(--bg-color);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: aliceblue;
    }

    .footer_bottom {
        /* margin-top: 80%; */
        /* padding-top: 100px; */
        position: relative;
        bottom: 0;
        /* left: 0; */
    }

    .footertext {
        font-size: 12px;
        line-height: 22px;
        margin-top: 22px;
    }

    .footer__list ul{
        padding: 0px;
    }

    .footer__list ul li {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 560;
    }

    .footer__list h3 {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0px;
    }
    .footer__list{
        text-align: center;
    }
    .footer__logo {
        height: 130px;
    }

    .footer__logo__image img {
        width: 80px;
    }
}