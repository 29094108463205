.privecy{
    margin-top: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.privecy_heading{

    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.privecy_heading h1{
    font-size: 48px;
    color: var( --bg-color);
}
.privecy_heading p{
    font-size: 16px;
    margin: 16px 0px 0px;
}
.privecy_main{
    max-width: 640px;
}
.privecy_main p{
    margin: 0px 0px 16px;
    font-size: 14px;
    line-height: 20px;
}
.privecy_main h2{
    margin: 32px 0px 16px;
    font-size: 20px;
    color: var(--bg-color);
}


@media(max-width:600px){
    .privecy{
        margin-top: 60px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
    }
    .privecy_heading h1{
        font-size: 36px;
    }
}

@media(max-width:400px){
    .privecy_heading h1{
        font-size: 32px;
    }
    .privecy_heading p{
        font-size: 14px;
        margin: 16px 0px 0px;
    }
}
@media(max-width:360px){
    .privecy_heading h1{
        font-size: 26px;
    }
    .privecy_heading p{
        font-size: 12px;
        margin: 16px 0px 0px;
    }
}