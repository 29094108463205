/* .about_us_page{
    margin-top: 60px;
} */
.about_us {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    height: 100vh;
    position: relative;
}

.about_left {
    width: 50%;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    position: relative;
    z-index: 1;
}

.about_left .about_contant h1 {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: var(--bg-color);
}

.about_left .about_contant p {
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 15px;
}

.about_btn {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 7px 25px;
    color: aliceblue;
    background-color: var(--bg-color);
    font-weight: 500;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    size: 192px 56px;
}

.about_bgi {
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.btn_div {
    display: flex;
}

.btn_img {
    width: 192px;
    height: 56px;
}

.btn_imgdiv {
    margin-right: 10px;
    width: fit-content;
    height: fit-content;
}

.about_right {
    width: 50%;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about_right .about_image img {
    max-width: 480px;
    border-radius: 20px;
}

.text-small4 {
    color: rgba(112, 111, 108, 1);
}

@media (max-width: 768px) {
    .about_left {
        width: 50%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .about_left .about_contant h1 {
        margin-bottom: 20px;
        font-size: 25px;
    }

    .about_left .about_contant p {
        line-height: 20px;
        font-size: 13px;
    }

    .about_right {
        width: 50%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .about_right .about_image img {
        max-width: 370px;
        border-radius: 20px;
    }
}

@media (max-width: 600px) {
    .about_us {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        align-items: center;
    }

    .about_left {
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;
        margin-bottom: 20px;
        /* remove this margin bottom */
    }

    .about_left .about_contant h1 {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .about_left .about_contant p {
        line-height: 17px;
        font-size: 12px;
    }

    .about_btn {
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 7px 17px;
        color: aliceblue;
        background-color: var(--bg-color);
        font-size: 10px;
        font-weight: 500;
    }

    .about_right {
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        order: 1;
        margin-bottom: 50px;
        object-fit: contain;
        overflow: hidden;
    }

    .about_right .about_image img {
        max-width: 335px;
        object-fit: contain;
        border-radius: 20px;
    }
}

@media (max-width: 440px) {
    .about_us {
        justify-content: flex-start;
        height: 90vh;
        position: relative;
        background-position: center;
        background-size: cover;
    }

    .about_left {
        width: 100%;
        height: fit-content;
        padding: 0 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: center;
    }

    .about_bgi {
        width: 100%;
        height: 47%;
        position: relative;
        background-position: 4%;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .about_left .about_contant h1 {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
    }

    .about_left .about_contant p {
        color: rgba(72, 72, 72, 1);
        font-size: 17px;
        line-height: 27px;
        font-weight: 500;
    }

    .btn_img {
        width: 168px;
        height: 49px;
    }

    .btn_div {
        justify-content: center;
        padding-top: 10px;
    }
}

@media (max-width: 350px) {
    .btn_img {
        width: 130px;
        height: 34px;
    }

}