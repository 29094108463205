
.useapp_page{
    height: 60vh;
}
.useapp_heading{
    display: flex;
    justify-content: center;
    color: var(--bg-color);
}
.useapp_heading h1{
    margin-bottom: 70px;
    color: var(--bg-color);
}
.useapp_flex{
    display: flex;
    justify-content: space-between;
    padding: 0px 100px;
    gap: 30px;
}

.appCard{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.appCardTop{
    height: 37%;
    display: flex;
    flex-direction: column;
}
.appCardBottom{
    height: 50%;
    display: flex;
    flex-direction: column;
}
.appCardBottom h3{
    color: var(--bg-color);
}
.appCardBottom p{
    font-size: 12px;
    font-weight: 500;
    color: rgb(160, 160, 160);
    max-width: 250px;
}
.appCard img{
    margin-bottom: 17px;
}
.appCard h3{
    font-size: 22px;
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    .useapp_flex{
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
        gap: 30px;
    }
  }
  
  @media (max-width: 600px) {
    .useapp_page{
        margin-top: 70px;
        height: auto;
    }
    .useapp_heading h1{
        margin-bottom: 70px;
        color: var(--bg-color);
        font-size: 25px;
    }
    .useapp_flex{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;
        gap: 10px;
    }
    .appCard{
        width: 300px;
        height: 290px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
    }
    .appCardTop{
        height: 37%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .appCardBottom{
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .appCardBottom h3{
        font-size: 14px;
    }
    .appCardBottom p{
        font-size: 12px;
        font-weight: 500;
        color: rgb(160, 160, 160);
        max-width: 250px;
    }
  }
  @media (max-width: 490px) {
      
  }
  @media (max-width: 380px) {
     
  }
  