* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
:root {
  --test-color: rgb(0, 0, 0);
  --bg-color: rgba(20, 58, 95, 1);
  --fonSizeHeading: 36px;
}

:root::-webkit-scrollbar-thumb {
  display: none;
}

:root::-webkit-scrollbar {
  width: 0px;
}

.arabic{
  direction: rtl;
}

.container {
  max-width: 100%;
  margin: 0 auto;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}

@media (max-width: 860px) {
}
@media (max-width: 768px) {
}
@media (max-width: 600px) {
  html,
  body {
    overflow-x: hidden;
    position: relative;
  }
}
@media (max-width: 490px) {
}