header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 90px;
    height: 93px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.6s ease-in-out;
    z-index: 2;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

header .logo img {
    width: 131px;
    height: 36px;
}

.navLinks ul {
    display: flex;
    gap: 40px;
    padding: 0px 0px 0px 240px;
}

.navLinks ul li {
    cursor: pointer;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: rgba(20, 58, 95, 1)
}

.navLinks ul li:hover {
    color: var(--bg-color);
}

.navLinks ul li::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0%;
    height: 2px;
    background-color: var(--bg-color);
    transition: all 0.5s ease-in-out
}

.navLinks ul li:hover::before {
    width: 100%;
}

.navLinkActive ul {
    display: flex;
    gap: 40px;
}

.navLinkActive ul li {
    cursor: pointer;
    position: relative;
    font-weight: 500;
    font-size: 14px;
}

.orangeButton {
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    background: rgba(254, 115, 50, 1);
    border: rgba(254, 115, 50, 1);
    border-radius: 100px;
    padding: 13px 28px;
    margin-top: 0px;
}

.logo {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
}

.menu {
    display: none;
}


@media (max-width:860px) {}

@media (max-width:768px) {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        height: 60px;
        z-index: 2;
    }
}

@media (max-width:600px) {
    header .logo img {
        width: 50px;

    }

    .navLinks {
        position: absolute;
        width: 100%;
        left: -90px;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        background-color: white;
        z-index: -1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 0px;
        overflow: hidden;
        transition: all 0.6s ease-in;
    }

    .navLinks ul {
        display: block;
    }

    .navLinks ul li {
        text-align: center;
        margin-bottom: 50px;
        font-size: 14px;
    }

    .navLinks ul li::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background-color: var(--bg-color);
        transition: all 0.5s ease-in-out
    }

    .navLinks ul li:hover::before {
        width: 0%;
    }

    .navLinkActive {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        background-color: white;
        z-index: -1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* text-align: left; */
        max-width: 100%;
        overflow: hidden;
        transition: all 0.6s ease-in;
    }

    .navLinkActive ul {
        display: block;
        margin-top: 50px;
        padding: 10px;
    }

    .navLinkActive ul li {
        text-align: left;
        margin-bottom: 0px;
        font-size: 16px;
        /* line-height: 10px; */
        color: rgba(20, 58, 95, 1);
    }


    .menu {
        display: block;
    }

}

@media (max-width:490px) {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        height: 60px;
        z-index: 2;
    }

    header .logo img {
        width: 102px;
        height: 28px;
    }
    .logo {
        margin: 10px;
    }
    .orangeButton {
        color: white;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        padding: 8px 20px;
        margin-top: 0px;
    }
}