.material_page{
    height: 60vh;
}
.material_heading{
    display: flex;
    justify-content: center;
}
.m_heading{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m_heading h1{
    font-size: var(--fonSizeHeading);
    color: var(--bg-color);
    margin-bottom: 10px;
}
.m_heading p{
    max-width: 400px;
    font-size: 12px;
    margin-bottom: 70px;
}

.material_cercle{
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    gap: 30px;
}
.cercle{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cercle .cercle_top{
    width: 150px;
    height: 150px;
    background-color: rgb(238, 238, 238);
    border-radius: 50%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cercle .cercle_bottom{
    max-width: 100px;
    text-align: center;
}
.cercle .cercle_bottom h4{
    font-size: 13px;
}




@media (max-width: 768px) {
    .material_cercle{
        display: flex;
        justify-content: space-between;
        padding: 0 50px;
        gap: 30px;
    }
    .m_heading h1{
        font-size: 25px;
        color: var(--bg-color);
        margin-bottom: 10px;
    }
  }
  
  @media (max-width: 600px) {
    .material_page{
        height: auto;
        margin-top: 70px;
    }
    .material_heading{
        display: flex;
        justify-content: center;
    }
    .m_heading{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .m_heading h1{
        font-size: 25px;
        color: var(--bg-color);
        margin-bottom: 10px;
    }
    .m_heading p{
        max-width: 360px;
        font-size: 10px;
        margin-bottom: 70px;
    }
    
    .material_cercle{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        gap: 10px;
    }
    .cercle{
        width: 300px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cercle .cercle_top{
        width: 150px;
        height: 150px;
        background-color: rgb(223, 223, 223);
        border-radius: 50%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cercle .cercle_bottom{
        max-width: 100px;
        text-align: center;
    }
    .cercle .cercle_bottom h4{
        font-size: 13px;
    }
  }
  @media (max-width: 440px) {
      
  }
  @media (max-width: 380px) {
      
  }
  