/* .about_us_page{
    margin-top: 60px;
} */
.reviews {
    display: flex;
    justify-content: space-between;
    height: 80vh;
}

.reviews_left {
    width: 60%;
    padding: 100px 108px 0px 108px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(20, 58, 95, 1);
}

.reviews_left .reviews_contant h1 {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
}

.reviews_left .reviews_contant p {
    line-height: 25px;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 200;
}
/* 
.reviews_contant {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.verifyicon {
    width: 20px;
    height: 20px;
    margin: 0px 4px -4px;
}

.reviews_btn {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 8px;
    color: aliceblue;
    background-color: rgba(20, 58, 95, 1);
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    font-size: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.reviews_btnimg {
    width: 20px;
    height: 20px;
}

.review_btndiv {
    display: flex;
}

.reviews_btn:hover {
    background-color: rgb(16, 45, 75);
}

.reviews_right {
    width: 40%;
    height: 100%;
    padding: 0 0px;
    position: relative;
    background-position: center;
    background-size: cover;
}

.reviews_right img {
    max-width: 100%;
    max-height: 100%;
}

.reviewshead {
    position: absolute;
    z-index: 1;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 24px;
    margin: 70px 110px;
}

.quotesign {
    position: absolute;
    top: 40px;
    right: 30px;
}

@media (max-width: 768px) {

    .reviews_left .reviews_contant h1 {
        margin-bottom: 20px;
        font-size: 25px;
    }

    .reviews_left .reviews_contant p {
        line-height: 20px;
        font-size: 13px;
    }

    .reviews_right {
        width: 50%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .reviews_right .reviews_image img {
        max-width: 370px;
        border-radius: 20px;
    }
}

@media (max-width: 600px) {
    .reviews_us {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        align-items: center;
    }

    .reviews_left {
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;
        margin-bottom: 20px;
        /* remove this margin bottom */
    }

    .reviews_left .reviews_contant h1 {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .reviews_left .reviews_contant p {
        line-height: 17px;
        font-size: 12px;
    }

    .reviews_btn {
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 7px 17px;
        color: aliceblue;
        background-color: rgba(20, 58, 95, 1);
        font-size: 10px;
        font-weight: 500;
    }

    .reviews_right {
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        order: 1;
        margin-bottom: 0px;
        object-fit: contain;
        overflow: hidden;
    }

    .reviews_right .reviews_image img {
        max-width: 335px;
        object-fit: contain;
        border-radius: 20px;
    }
}

@media (max-width: 440px) {

    .reviews {
        flex-direction: column;
        height: 90vh;
    }

    .reviews_right {
        width: 100%;
        height: 40%;
    }

    .reviews_left {
        width: 100%;
        height: fit-content;
        padding: 10px 10px;
        /* display: flex;
        flex-direction: column;
        justify-content: center; */
    }

    .reviewshead {
        margin: 10px 10px;
        padding: 10px;
        font-size: 16px;
    }

    .quotesign {
        top: 30px;
        right: 20px;
    }

    .reviews_left .reviews_contant h1 {
        margin-bottom: 1px;
        font-size: 26px;
        line-height: 36px;
        font-weight: 400;
    }

    .reviews_left .reviews_contant p {
        line-height: 25px;
        font-size: 16px;
        margin: 15px 7px;
        font-weight: 250;
    }

    .reviews_btn {
        border-radius: 5px;
        padding: 7px 10px 27px 10px;
        font-weight: 500;
        cursor: pointer;
        width: 22px;
        height: 22px;
        font-size: 30px;
        margin: 20px;
    }

}

@media (max-width: 380px) {}