.services_page{
    margin-top: 70px;
    height: 100vh;
}
.service_heading{
    display: flex;
    justify-content: center;
}
.service_heading h1{
    font-size: var(--fonSizeHeading);
    color: var(--bg-color);
    margin-bottom: 70px;
}
.services{
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.serviceCard{
    flex: 1;
    position: relative;
    height: 450px;
    background-color: rgb(236, 236, 236);
    border-radius: 6px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;

}
.serviceCard::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.404);
}
.service-contant{
    position: absolute;
    z-index: 1;
    max-width: 300px;
}
.service-contant h4{
    color: aliceblue;
    margin-bottom: 10px;
}
.service-contant p{
    font-size: 11px;
    color: aliceblue;
    margin-bottom: 10px;
    
}
.service-contant button{
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 7px 20px;
    color: var(--bg-color);
    background-color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .service_heading h1{
        font-size: 25px;
        margin-bottom: 70px;
    }
    .services{
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .serviceCard{
        flex: 1;
        height: 450px;
        background-color: rgb(236, 236, 236);
        overflow: hidden;
    }
  }
  
  @media (max-width: 600px) {
    .services_page{
        margin-top: 70px;
        height: auto;
    }
    .services{
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        height: 160vh;
    }
    .serviceCard{
        width: 100%;
        background-color: rgb(236, 236, 236);
        overflow: hidden;
    }
    .service-contant{
        position: absolute;
        z-index: 1;
        max-width: 300px;
    }
    .service-contant h4{
        color: aliceblue;
        margin-bottom: 10px;
    }
    .service-contant p{
        font-size: 11px;
        color: aliceblue;
        margin-bottom: 10px;
    }
    .service-contant button{
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 6px 10px;
        color: var(--bg-color);
        background-color: rgb(255, 255, 255);
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
    }
   
  }
  @media (max-width: 440px) {
      
  }
  @media (max-width: 380px) {
      
  }
  