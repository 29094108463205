

.img1 {
  margin-top: 120px;
  border-radius: 36px;
  height: 625px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  line-height: 100px;
  text-align: center;
  padding-top: 130px;
  position: relative;
}

.img1::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 36px;
  background-color: rgba(20, 58, 95, 0.5);
}

.float-item{
  position: relative;
  }

.text-big {
  color: white;
  line-height: 100px;
  font-size: 68px;
  font-weight: 600;
  margin-left: 200px;
  margin-right: 200px;
}

.text-small {
  color: white;
  line-height: 36px;
  font-size: 24px;
  font-weight: 400;
  /* margin-left: 200px; */
  margin: 16px 150px;
}

.img1 .orangebutton {
  color: white;
  background: rgba(254, 115, 50, 1);
  border: rgba(254, 115, 50, 1);
  border-radius: 100px;
  padding: 16px 60px;
  margin-top: -12px;
  font-size: 20px;
  /* line-height: 25px; */
  font-weight: 600;
}


.text2-small {
  color: rgba(112, 111, 108, 1);
}

.hero-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  /* background-color: black; */
  width: 100%;
  height: auto;
  padding: 0px 20px ;
}


@media (max-width: 768px) {
  /* .hero {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
  } */

}

@media (max-width: 600px) {
  /* .hero {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

}

@media (max-width: 490px) {
  .hero {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .hero-container {
    width: 100%;
    height: fit-content;
    padding: 0px 5px ;
  }
  .container{
    display: flex;
    justify-content: center;
  }
  .img1 {
    margin-top: 80px;
    border-radius: 20px;
    height: 78vh;
    width: 95%;
    text-align: center;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .img1::before{
    border-radius: 20px;
  }

  .text-big {
    line-height: 47px;
    font-size: 35px;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .text-small {
    color: white;
    line-height: 24px;
    font-size: 17px;
    font-weight: 280;
    margin-left: 35px;
    margin-right: 35px;
    
  }
  .img1 .orangebutton {
    border-radius: 100px;
    padding: 13px 90px;
    margin: 10px 10px;
    font-size: 20px;
    color: white;
  }
  
}