.screenShortPage {
  height: 80vh;
}

.screen-short_heading {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  height: 80vh;
  position: relative;
  background-position: center;
  background-size: cover;
}

.scr_heading {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 30%;
  height: 100%;
  margin-left: 80px;
}

.scr_heading h1 {
  font-size: 80px;
  font-weight: 500;
  line-height: 100px;
  color: var(--bg-color);
  margin-bottom: 10px;

}

.scr_heading p {
  max-width: 500px;
  font-size: 14px;
  margin-bottom: 70px;
}

.smalltext5{
  color: rgba(20, 58, 95, 1);
  line-height: 34px;
  font-size: 20px;
  font-weight: 500;
  /* text-align: left; */
}

.screen_ui {
  padding: 0px 100px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.screen_ui .screenPhonto {
  width: 300px;
  height: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.screenPhonto img {
  width: 100%;
}

@media(max-width:600px) {
  .scr_heading h1 {
    font-size: 22px;
    color: var(--bg-color);
    margin-bottom: 10px;
    margin-top: 40px;
    /* remove how to use area */

  }

  /* .scr_heading p {
    max-width: 300px;
    font-size: 11px;
    margin-bottom: 70px;
  } */

  .screen_ui {
    width: 100%;
    overflow: hidden;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    overflow-x: scroll;
    /* flex-wrap: wrap; */
  }

  .screenPhonto {
    border-radius: 5px;
    overflow: hidden;
  }

}

@media(max-width:440px) {
  .screen-short_heading {
    height: 77vh;
    margin: 10px;
    position: relative;
    background-position: 80%;
    background-size: cover;
}
  .scr_heading {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }
  .scr_heading h1 {
    font-size: 40px;
    line-height: 60px;
    color: var(--bg-color);
    margin-bottom: 10px;
    margin-top: 40px;
}
.smalltext5 {
  color: rgba(20, 58, 95, 1);
  line-height: 21px;
  font-size: 16px;
}
}